import Vue from 'vue'
import App from './App.vue'

import router from './router';
import store from './store';
import Toast from './plugins/toast';
import VueCurrencyFilter from 'vue-currency-filter'
import VueCarousel from 'vue-carousel';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import DateRangePicker from '@gravitano/vue-date-range-picker';
import VueClickOutsideElement from 'vue-click-outside-element';
import VueTheMask from 'vue-the-mask'
import money from 'v-money';
import VTooltip from 'v-tooltip';
import VueFab from 'vue-float-action-button';
import vSelect from 'vue-select';

// import Tooltip from './plugins/tooltip';
import Modal from './plugins/modal';

Vue.config.productionTip = false
Vue.use(VueCarousel);
Vue.use(DateRangePicker);
Vue.use(VueClickOutsideElement);
Vue.use(VueTheMask);
Vue.use(money, {precision: 4});
Vue.use(Toast);
Vue.use(Modal);
Vue.use(VTooltip);
Vue.component('v-select', vSelect);
Vue.use(VueMoment, {
  moment,
});
Vue.use(VueFab, {});
Vue.filter('subofx', function (value) {
  let length = value.length;
  let html = value.substring(0,40);
  if(length > 40) {
    html += '...';
  }
  return html;
});
Vue.use(VueCurrencyFilter,
{
  symbol : 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
